import {
	Box,
	Button,
	Container,
	Heading,
	Image,
	Stack,
	Flex,
	Spacer,
} from "@chakra-ui/react";
import axios from "axios";
import ProjectCard from "./ProjectCard";

function Home({ logout }) {
	const baseUrl = `${
		process.env.REACT_APP_STAGE == "production" ? "http://avptools.com" : ""
	}`;

	const images = require.context("../assets/", true);
	const imageList = images.keys().map((image) => images(image));

	const handleLogout = async () => {
		try {
			await axios.post(
				`${
					process.env.REACT_APP_STAGE == "production"
						? process.env.REACT_APP_SERVER_URL_PROD
						: process.env.REACT_APP_SERVER_URL_DEV
				}/auth/logout`,
				{},
				{ withCredentials: true }
			);
			logout(false);
		} catch (error) {
			console.error(error);
		}
	};
	console.log(imageList);

	const projects = {
		reportGenerator: {
			imageURL: imageList[8],
			title: "Report Generator",
			description:
				"Report Generator is used by AviaPro analysts to generate client AviAnalysis Reports.",
			baseURL: baseUrl,
			projectSubDomain: "reportGenerator",
			buttonTitle: "Report Generator",
			isDisabled: false,
			documentation: "RG User Manual",
			documentationLink:
				"https://aviaproconsulting.sharepoint.com/:w:/s/eCommerceTechnical/EXFsxsNJi19Mm7RRgJ09chABol_9gLqTWQbUFx3OYPIo4A?e=lswvIc",
			documentationDisabled: false,
		},
		database: {
			imageURL: imageList[3],
			title: "Database V2",
			description:
				"Database V2 is used to house all internal data collected from different sources. It is primarily used to feed data to other AviaPro software.",
			baseURL: baseUrl,
			projectSubDomain: "database",
			buttonTitle: "Database",
			isDisabled: false,
			documentation: "Database User Manual",
			documentationLink:
				"https://aviaproconsulting.sharepoint.com/:b:/s/eCommerceTechnical/EQm3i7lczlhIlx4_0X0J_OABKRPRiCwK7wVePDL51ufDeg?e=nsguJV",
			documentationDisabled: false,
		},
		kpiTool: {
			imageURL: imageList[0],
			title: "KPI Tools",
			description: "KPI Tools",
			baseURL: baseUrl,
			projectSubDomain: "kpi",
			buttonTitle: "KPI",
			isDisabled: false,
			documentation: "KPI User Manual",
			documentationLink:
				"https://aviaproconsulting.sharepoint.com/:w:/s/eCommerceTechnical/EXFsxsNJi19Mm7RRgJ09chABol_9gLqTWQbUFx3OYPIo4A?e=lswvIc",
			documentationDisabled: true,
		},
		routeMapper: {
			imageURL: imageList[6],
			title: "Route Mapper",
			description: "Mapping software for analysts.",
			baseURL: baseUrl,
			projectSubDomain: "routeMapper",
			buttonTitle: "Route Mapper",
			isDisabled: false,
			documentation: "Router Mapper User Manual",
			documentationLink:
				"https://aviaproconsulting.sharepoint.com/:w:/s/eCommerceTechnical/EUFJ47SLZVxMkBCWXP6SS-4B1JgJWn8kDKEgtbo0Qs565w?e=Zd7Lgo",
			documentationDisabled: false,
		},
		demoForms: {
			imageURL: imageList[4],
			title: "AviAnalysis Demo Forms",
			description:
				"Independant and logic/constraint free AviAnalysis forms made specifically for demonstration purposes to clients or new hires.",
			baseURL: "http://avianalysisv2demov2.s3-website-us-east-1.amazonaws.com",
			projectSubDomain: "",
			buttonTitle: "AviAnalysis Demo",
			isDisabled: false,
			documentation: "Demo Forms User Manual",
			documentationLink:
				"https://aviaproconsulting.sharepoint.com/:w:/s/eCommerceTechnical/EXFsxsNJi19Mm7RRgJ09chABol_9gLqTWQbUFx3OYPIo4A?e=lswvIc",
			documentationDisabled: true,
		},
		bflConverter: {
			imageURL: imageList[2],
			title: "BFL Converter",
			description: "BFL Converter",
			baseURL: baseUrl,
			projectSubDomain: "bfl",
			buttonTitle: "BFL Converter",
			isDisabled: false,
			documentation: "BFL-C User Manual",
			documentationLink:
				"https://aviaproconsulting.sharepoint.com/:w:/s/eCommerceTechnical/EXFsxsNJi19Mm7RRgJ09chABol_9gLqTWQbUFx3OYPIo4A?e=lswvIc",
			documentationDisabled: true,
		},
	};

	const devProjects = {
		dashboard: {
			imageURL: imageList[5],
			title: "AviAnalysis Dashboard",
			description:
				"AviAnalysis Dashboard is the new iteration of AA which will give the user a new way to use and order AviAnalysis.",
			baseURL: baseUrl,
			projectSubDomain: "avDashboard",
			buttonTitle: "AviAnalysis Dashboard",
			isDisabled: false,
			documentation: "AviAnalysis Dashboard User Manual",
			documentationLink:
				"https://aviaproconsulting.sharepoint.com/:b:/s/eCommerceTechnical/EQm3i7lczlhIlx4_0X0J_OABKRPRiCwK7wVePDL51ufDeg?e=nsguJV",
			documentationDisabled: true,
		},
		database: {
			imageURL: imageList[3],
			title: "Database V2 - Test Server",
			description:
				"Database V2 is used to house all internal data collected from different sources. It is primarily used to feed data to other AviaPro software.",
			baseURL: baseUrl,
			projectSubDomain: "databaseTesting",
			buttonTitle: "Database - TEST",
			isDisabled: false,
			documentation: "Database User Manual",
			documentationLink:
				"https://aviaproconsulting.sharepoint.com/:b:/s/eCommerceTechnical/EQm3i7lczlhIlx4_0X0J_OABKRPRiCwK7wVePDL51ufDeg?e=nsguJV",
			documentationDisabled: false,
		},
		devForms: {
			imageURL: imageList[5],
			title: "AviAnalysis Dev Forms",
			description:
				"AviAnalysis forms made for feedback and debugging purposes.",
			baseURL: baseUrl,
			projectSubDomain: "forms/?order=1235",
			buttonTitle: "AviAnalysis Forms",
			isDisabled: false,
			documentation: "Dev Forms User Manual",
			documentationLink:
				"https://aviaproconsulting.sharepoint.com/:w:/s/eCommerceTechnical/EXFsxsNJi19Mm7RRgJ09chABol_9gLqTWQbUFx3OYPIo4A?e=lswvIc",
			documentationDisabled: true,
		},
		reportGenerator: {
			imageURL: imageList[7],
			title: "Report Generator - Test Server",
			description:
				"Report Generator is used by AviaPro analysts to generate client AviAnalysis Reports. This is a test server to test bugs/features before uploading to production.",
			baseURL: baseUrl,
			projectSubDomain: "reportGeneratorTest",
			buttonTitle: "Report Generator - TEST",
			isDisabled: false,
			documentation: "RG User Manual",
			documentationLink:
				"https://aviaproconsulting.sharepoint.com/:w:/s/eCommerceTechnical/EXFsxsNJi19Mm7RRgJ09chABol_9gLqTWQbUFx3OYPIo4A?e=lswvIc",
			documentationDisabled: false,
		},
		econModel: {
			imageURL: imageList[6],
			title: "RPM",
			description: "Route Profitability Model (RPM)",
			baseURL: baseUrl,
			projectSubDomain: "RPM",
			buttonTitle: "RPM",
			isDisabled: true,
			documentation: "RPM User Manual",
			documentationLink:
				"https://aviaproconsulting.sharepoint.com/:w:/s/eCommerceTechnical/ETRL_hGiT7tNrlzK51h-lz4B20fuxvV_WuhhL1CibMDkgQ?e=KZgfh1",
			documentationDisabled: false,
		},
	};

	return (
		<Container
			maxW="container.xl"
			py="6"
			className="bg-light sticky-top navbar-expand"
		>
			<Flex align="center" justify="space-between" mb="8">
				<Flex
					// position={"absolute"}
					w={{ base: "20%", sm: "13%", md: "10%", lg: "10%" }}
					left={3}
					top={3}
				>
					<a href="https://aviaproconsulting.ca/">
						<Image src="/company.png" objectFit="cover" />
					</a>
				</Flex>
				<Spacer />
				<Box>
					<Heading
						as="h2"
						size={{ base: "lg", sm: "lg", md: "lg", lg: "lg" }}
						fontWeight="bold"
					>
						Production Internal Tools
					</Heading>
				</Box>
				<Spacer />
				<Box>
					<Button
						size={{ base: "xs", sm: "xs", md: "md" }}
						colorScheme="red"
						onClick={handleLogout}
					>
						Logout
					</Button>
				</Box>
			</Flex>
			<Stack spacing={4}>
				{Object.keys(projects).map((project, index) => (
					<ProjectCard
						key={project}
						imageURL={projects[project].imageURL}
						title={projects[project].title}
						description={projects[project].description}
						baseURL={projects[project].baseURL}
						projectSubDomain={projects[project].projectSubDomain}
						buttonTitle={projects[project].buttonTitle}
						isDisabled={projects[project].isDisabled}
						documentation={projects[project].documentation}
						documentationLink={projects[project].documentationLink}
						documentationDisabled={projects[project].documentationDisabled}
					/>
				))}
				<Flex pt={"6em"} pb={"1em"} justifyContent={"center"} w={"full"}>
					<Heading
						as="h1"
						size={{ base: "lg", sm: "lg", md: "lg", lg: "lg" }}
						fontWeight="bold"
					>
						Internal Tools in Development
					</Heading>
				</Flex>
				{Object.keys(devProjects).map((project, index) => (
					<ProjectCard
						key={project}
						imageURL={devProjects[project].imageURL}
						title={devProjects[project].title}
						description={devProjects[project].description}
						baseURL={devProjects[project].baseURL}
						projectSubDomain={devProjects[project].projectSubDomain}
						buttonTitle={devProjects[project].buttonTitle}
						isDisabled={devProjects[project].isDisabled}
						documentation={devProjects[project].documentation}
						documentationLink={devProjects[project].documentationLink}
						documentationDisabled={devProjects[project].documentationDisabled}
					/>
				))}
			</Stack>
		</Container>
	);
}

export default Home;
